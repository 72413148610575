import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Contrato } from 'src/app/models/Contrato';
import { Pago } from 'src/app/models/Pago';
import { PagosService } from 'src/app/services/pagos/pagos.service';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
  modalRef: BsModalRef;
  config = {
    class: 'modal-lg',
    backdrop: true,
    ignoreBackdropClick: true,
  };
  from: NgbDateStruct;
  to: NgbDateStruct;
  status: any[] = ["Vigentes", "Cancelados", "Todos"];
  clientes: any[] = [];
  formasPago: any[] = [];
  contratos: any[] = [];
  pagos: any[] = [];
  listpagos: any[] = [];
  model: Pago;
  liquidarContratoDisabled: boolean = false;
  importesugerido: any;
  fechaSelect: boolean = false;
  importeAplicado: boolean = false;
  idCliente: number;
  StatusSelect: number = 0;
  formaPago: number;
  contraentregaAnticipo: boolean = false;
  importAply: boolean = false;;

  constructor(private modalService: BsModalService,private _pagosService: PagosService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getCombos();
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Pago();
    this.pagos = [];
    this.contraentregaAnticipo = false;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.config);
    } 
  }

  clienteSelect(){
    this.handelPago();
    this._pagosService.getContratos(this.model.Usuario)
    .subscribe(
      data => {
        console.log(data)
        this.contratos = data
      },
        error => this.toastr.error(error.message, 'Error!')
    );
  }
  aplicarImporte(){
    console.log(this.model.Fecha);
    this._pagosService.aplicarImporte(this.model.ID_Contrato, this.model.Importe, this.model.Fecha)
    .subscribe(
      data => {
        console.log(data)
        this.pagos = data
        this.importAply= true;
      },
        error => this.toastr.error(error.message, 'Error!')
    );
  }

  onSubmit(FormData){

    if (!this.importAply){
      this.toastr.error("Aplicar pago", 'Error!')
    }

    if (FormData.valid && this.model.FormaPago && this.model.Importe && this.importAply){
      const data = {
        data: this.model,
        detalles: this.pagos
      }
  
      this._pagosService.pagoContrato(data).subscribe(
        data =>{
          this.toastr.success('Pago realizado con exito.', 'Guardado!');
          this.pagos = [];
            this.modalRef.hide();
            this._pagosService.getPagosByClient('', '', this.model.Usuario, 0, this.model.FormaPago)
              .subscribe(
                data => {
                  this.listpagos = data
                },
                error => this.toastr.error(error.message, 'Error!') );
              },
              error => 
                this.toastr.error(error.message, 'Error!') 
            );
    }
    
  }

  handelPago(){
    this.importeAplicado = false;
  }

  getCombos(){
    this._pagosService.getCombos()
      .subscribe(
        data => {
          console.log(data);
          this.clientes = data.clientes
          this.formasPago = data.metodospago
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  getPagos(){
    this._pagosService.getPagos(this.model.ID_Contrato, this.model.Fecha)
      .subscribe(
        data => {
          console.log(data)


          const contraentrega = data.find(item => item.Descripcion === 'Contraentrega');
          const anticipo = data.find(item => item.Descripcion === 'Anticipo');

          if (contraentrega) {
            this.pagos = [contraentrega];
            this.contraentregaAnticipo = true;
            this.model.Importe = this.pagos[0].importeTotal;
          } else if (anticipo) {
            this.pagos = [anticipo];
            this.contraentregaAnticipo = true;
            this.model.Importe = this.pagos[0].importeTotal;
          } else {
            this.pagos = data;
          }

        },
        error => this.toastr.error(error.message, 'Error!') );

  }


  getPagosByClient(){
    let from = '';
    let to = ''; 
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }
    this.listpagos = []

    this._pagosService.getPagosByClient(from, to, this.idCliente, 0, this.formaPago)
      .subscribe(
        data => {
          console.log(data)
          this.listpagos = data
        },
        error => this.toastr.error(error.message, 'Error!') );
  }
}
