export class Pago {

    ID:number;
    Fecha: Date;
    Importe: number;
    Lugar: string;
    Observaciones: string;
    Status: boolean;
    Usuario: number;
    ID_Contrato: number;
    ID_Sucursal: number;
    FormaPago: number;
}