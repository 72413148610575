export class Entrada{
    constructor() { 
    }
    
    Folio: number;
    Fecha: Date;
    ID_TipoEntradaSalida: number;
    TipoMovimiento: number;
    ID_Almacen: number;
    Observaciones: string;
}