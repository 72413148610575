import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import { Contrato } from 'src/app/models/Contrato';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
import { ContratoDetalle } from 'src/app/models/ContratoDetalle';
import { throwError } from 'rxjs';
import { UsersService } from 'src/app/services/service.index';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.css']
})
export class ContratosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  model: Contrato = new Contrato();
  modelProducto: ContratoDetalle = new ContratoDetalle();
  modalRefProd: BsModalRef;
  modalRef: BsModalRef;
  productos: any[] = [];
  sucursales: any[] = [];
  clientes: any[] = [];
  diagnosticos: any[] = [];
  pacientes: any[] = [];
  diagnosticoDisabled: boolean = true;
  anticipoDisabled: boolean = false;
  contraEntregaDisabled: boolean = false;
  gastoCobroDisabled: boolean = false;
  clienteDisabled:boolean = true;
  diaSemanaDisabled:boolean = true;
  productoSelected: any;
  detalles: any[] = [];
  isChecked: boolean = true;
  frecuenciasPagos: any[] = ["Mensual", "Quincenal", "Catorcenal", "Semanal"]
  DiasSemana: any[] = ["Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"]
  frecuanciaSelect: string;
  diaSemanaSelect: string;
  total: number = 0;
  detallesActives: boolean = false;
  corrido: any[] = [];
  almacenes: any[] = [];
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  handelPeriodo: boolean = false;

  constructor(private modalService: BsModalService, private _userService:UsersService, private toastr: ToastrService, private _contratoService: ContratosService) { }


  ngOnInit() {
    this.getCombos();
  }

  handlePeriodos(){
    this.handelPeriodo = false;
  }

  handleDetalles(){
    this.detallesActives = true;
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      if (this.contraEntregaDisabled){
        
        if (!this.model.fechaEspEnt){
          this.toastr.error('Fecha de contraentrega.', 'Error!');
        }else{
          const fechaEspEnt = new Date(this.model.fechaEspEnt);
          const fechaInicial = new Date(this.model.Fecha);
          if (fechaEspEnt < fechaInicial){
            this.toastr.error('Fecha de contraentrega menor a fecha inicial.', 'Error!');
          }else{
            if (this.handelPeriodo){
              this.model.Restante = this.model.ImportePrestamo;
              this.model.ocupaAnticipo = this.anticipoDisabled;
              this.model.ocupaCE = this.contraEntregaDisabled;
              this.model.gastoCob = this.gastoCobroDisabled;
              this.model.ID_UsuarioCreacion = this._userService.user.ID;
      
              const data = {
                data: this.model,
                detalles: this.corrido,
                detallesContrato: this.detalles
              }
      
              this._contratoService.guardar(data)
              .subscribe(
                () =>{
                  this.toastr.success('Contrato guardado con exito.', 'Guardado!');
                  this.detalles = [];
                  this.corrido = [];
                  this.model = new Contrato();
                  this.setFechaActual();
                  this.getID();
                  this.detallesActives = false;
                  FormData.resetForm();
                }
              );
            }else{
              this.toastr.error('Calcular pagos.', 'Haga el calculo de pagos!');
      
            }
          }
        }
        
      }
      
      
    }
  }

  setAlamacenes(selectedSucursal: any) {
    this._contratoService.getAlmacenes(selectedSucursal)
    .subscribe(
      data=>{
        if (data)
        this.almacenes = data;
      }
    );
  }

  onSubmitDetalle(FormData){
    if (FormData.valid){
      this._contratoService.getProducto(this.modelProducto.Producto)
      .subscribe(
        data => {
          this.modelProducto.Clave = data.producto.Clave
          this.modelProducto.Descripcion = data.producto.Descripcion
          this.modelProducto.Costo = data.producto.Costo
          this.modelProducto.Precio = data.producto.Precio
          this.modelProducto.PContado = data.producto.PrecioPP
        }
      );

      this._contratoService.getPaciente(this.modelProducto.ID_Paciente)
      .subscribe(
        data => {
          this.modelProducto.Paciente = data.paciente.Nombre
        }
      );

      this.handleDetalles();
      this.detalles.push(this.modelProducto);
      this.calcularTotal();
      this.toastr.success('Producto agregado con exito.', 'Agregado!');
      this.modalRefProd.hide();
      console.log(this.detalles)
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Contrato();
    this.productos = [];
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.config);
    } 
  }

  newPaciente(value:any){
    this._contratoService.getDiagnosticos(value)
    .subscribe(
      data => {
        this.diagnosticos = data.nuevaLista;
        this.diagnosticoDisabled = false;
      }
    );
  }

  newProducto(value:any){
    this._contratoService.getProducto(value)
    .subscribe(
      data => {
        this.modelProducto.Precio = data.producto.Precio
        this.modelProducto.PCredito = data.producto.PrecioCred
        this.modelProducto.Cantidad = 1;
        this.modelProducto.VentaDetalle = this.modelProducto.Cantidad * this.modelProducto.Precio;
      }
    );
  }

  handelCantidad(){
    if (this.modelProducto.Cantidad <= 0) {
      this.modelProducto.Cantidad = 1;
    }
    if (this.modelProducto.Precio){
      this.modelProducto.VentaDetalle = this.modelProducto.Cantidad * this.modelProducto.Precio;
    }
  }

  onShowNewProduct(template: TemplateRef<any>){
    this._contratoService.getPacientes(this.model.ID_Cliente)
    .subscribe(
      data => {
        this.pacientes = data.pacientes;
      }
    );
    if (this.model.ID_Cliente){
      this.modelProducto = new ContratoDetalle();
      this.modalRefProd = this.modalService.show(template, this.config);
    }
  }
  
  getCombos() {
    this.setFechaActual();
    this.getID();
    this._contratoService.getCombos()
      .subscribe(
        data => {
          console.log(data)
          this.sucursales = data.sucursales;
          this.clientes = data.clientes;
          this.productos = data.productos;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  getID(){
    this._contratoService.getLastContrato()
    .subscribe(
      data => {
        this.model.ID = data+1;
      }
    );
  }

  onFrecuenciaChange() {
    this.model.frecuencia = this.frecuenciasPagos.indexOf(this.frecuanciaSelect) + 1;
    if (this.model.frecuencia == 1){
      this.diaSemanaDisabled = false;
    }else{
      this.diaSemanaDisabled = true;
      this.model.DiaCobro = 0;

    }
    this.handelPeriodo = false;
  }

  onDiaSemanaChange(){
    this.model.DiaCobro = this.DiasSemana.indexOf(this.diaSemanaSelect) + 1;
  }

  limitInput(event: any) {
    if (event.target.value <= 0) {
      event.target.value = 1;
      this.model.Plazo = 1; 
    }
  }
  importeTotal: number;
  saldoFAux: number;

  calcularTotal(){
    this.model.Total = 0;
    this.detalles.forEach(detalle => {
      this.model.Total += detalle.PCredito;
    })
    this.model.ImportePrestamo = this.model.Total - this.model.anticipo - this.model.Contraentrega
    this.model.ImportePago = this.model.ImportePrestamo / this.model.Plazo;
  }

 

  calcularCorrido(FormData){

    const pagos = {
      Mensual: "Mensualidad",
      Catorcenal: "Catorcena",
      Quincenal: "Quincena",
      Semanal: "Semana"
    }

    

    if (FormData.valid) {
      if (this.detalles.length > 0){
        this.corrido = [];
        let saldo = this.model.ImportePrestamo;
        let fechaInicial = new Date(this.model.fechaInicial);
        let saldoF = this.model.Total;
        this.saldoFAux = this.importeTotal;


        if (this.anticipoDisabled){
          const fecha = new Date();
          const anio = fecha.getFullYear();
          const mes = String(fecha.getMonth() + 1).padStart(2, '0');
          const dia = String(fecha.getDate()).padStart(2, '0');
          const fechaFormateada = `${anio}-${mes}-${dia}`;
          saldoF -= this.model.anticipo;
          const model = {
            Fecha: fechaFormateada,
            Descripcion: "Anticipo",
            Importe: this.model.anticipo,
            Saldo: this.model.Total,
            SaldoF: saldoF,
          }
          this.corrido.push(model);
        }

        if (this.contraEntregaDisabled){
          const fecha = new Date(this.model.fechaEspEnt);
          const anio = fecha.getFullYear();
          const mes = String(fecha.getMonth() + 1).padStart(2, '0');
          const dia = String(fecha.getDate() + 1).padStart(2, '0');
          const fechaFormateada = `${anio}-${mes}-${dia}`;
          const model = {
            Fecha: fechaFormateada,
            Descripcion: "Contraentrega",
            Importe: this.model.Contraentrega,
            Saldo: saldoF,
            SaldoF: (this.anticipoDisabled ? this.model.Total - this.model.Contraentrega - this.model.anticipo : this.model.Total - this.model.Contraentrega)
          }
          saldoF -= this.model.Contraentrega;

          this.corrido.push(model);
        }


       

        for (let i = 0; i < this.model.Plazo; i++) {
          const fecha = new Date(fechaInicial);
          const anio = fecha.getFullYear();
          const mes = String(fecha.getMonth() + 1).padStart(2, '0');
          const dia = String(fecha.getDate()+1).padStart(2, '0');
          const fechaFormateada = `${anio}-${mes}-${dia}`;
          saldoF -= this.model.ImportePago
          const model = {
            Fecha: fechaFormateada,
            Descripcion: pagos[this.frecuanciaSelect] + " - " + (i+1),
            Importe: Math.max(0, parseFloat(this.model.ImportePago+"")),
            Saldo: Math.max(0, parseFloat(saldo+"")),
            SaldoF: Math.max(0, parseFloat(saldoF+"")),
          }
          this.corrido.push(model);
          if (this.frecuanciaSelect == "Mensual"){
            fechaInicial.setMonth(fechaInicial.getMonth() + 1);
          } else if (this.frecuanciaSelect == "Quincenal"){
            fechaInicial.setDate(fechaInicial.getDate() + 15);
          } else if (this.frecuanciaSelect == "Catorcenal"){
            fechaInicial.setDate(fechaInicial.getDate() + 14);
          } else if (this.frecuanciaSelect == "Semanal"){
            fechaInicial.setDate(fechaInicial.getDate() + 7);
          }
          saldo -= this.model.ImportePago
        }
        this.handelPeriodo = true;
        console.log(this.corrido)
      }else{
        this.toastr.error('Agregue detalles.', 'Agregue almenos un producto!');
      }
    }else{
      this.toastr.error('Formulario incompleto.', 'Rellenar todos los campos!');
      
    }
  }

  setFechaActual() {
    let fechaActual;
    const hoy = new Date();
    const año = hoy.getFullYear();
    const mes = (hoy.getMonth() + 1).toString().padStart(2, '0');
    const día = hoy.getDate().toString().padStart(2, '0');
    fechaActual = `${año}-${mes}-${día}`;
    this.model.Fecha = fechaActual;
  }

}
