export class ItemSelectProducto {
    Nombre: string = "";
    Existencia: number = 0;
    Disponible: number = 0;
    CostoPromedio: number = 0;
    CostoUltimo: number = 0;
    Precio: number = 0;
    IVA: number = 0;
    constructor(){}

}